

const pages = [
  {name: "Home", category: 'General', href:'/', header: false, footer: false, logged_in_only: false, logged_out_only: false},
  {name: "About", category: 'General', href:'/about', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Services", category: 'Services', href:'/services', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Fleet", category: 'General', href:'/fleet', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Contact", category: 'General', href:'/contact', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Careers", category: 'General', href:'/careers', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Driver Application", category: 'Forms', href:'/driver_application', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Quote", category: 'Forms', href:'/quote', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  
  {name: "Oil and Gas", category: 'Services', href:'/services/oil_and_gas', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Mining", category: 'Services', href:'/services/mining', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Logistics and Brokerage", category: 'Services', href:'/services/logistics_and_brokerage', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Hot Shot", category: 'Services', href:'/services/hot_shot', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Winch and Skid", category: 'Services', href:'/services/winch_and_skid', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Less than Truckload", category: 'Services', href:'/services/less_than_truckload', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Courier", category: 'Services', href:'/services/courier', header: false, footer: true, logged_in_only: false, logged_out_only: false},
];


const fleet = [
  {id: "super-b_flatdeck",hasDatasheet: true, name: "Super-B Flatdeck", use:"Used for steel coil, steel plate, and lumber.", image: "superb_flatdeck.png", weight: "92,000 lbs", prefill_id: 7, prefill_message: "I am requesting a Super-B Flatdeck."},
  // {name: "Super-B Van", use:"Used for dry transport.", image: "superb_van.png", weight: "92,000 lbs"},
  // {name: "Dry Van", use:"Used for dry transport.", image: "dry_van.png", weight: "40,000 lbs"},
  // {name: "Cold Van", use:"Used for cold goods transport.", image: "cold_van.png", weight: "40,000 lbs"},
  {id: "heavy_loads",hasDatasheet: true, name: "Heavy Loads", use:"7 to 10 axles can be assembled for extra-heavy, over-dimensional loads.", image: "heavy_loads.png", weight: "90,000+ lbs", prefill_id: 8, prefill_message: "I am requesting a customized Heavy Load."},
  {id: "triaxle_stepdeck",hasDatasheet: true, name: "Triaxle Stepdeck", use:"Also known as a drop-deck. Ideal for healing over-dimensional equipment.", image: "triaxle_stepdeck.png", weight: "62,000 lbs", prefill_id: 9, prefill_message: "I am requesting a Triaxle Stepdeck."},
  {id: "triaxle_flatdeck",hasDatasheet: true, name: "Triaxle Flatdeck", use:"Also known as a drop-deck. Ideal for healing over-dimensional equipment.", image: "triaxle_flatdeck.png", weight: "62,000 lbs", prefill_id: 9, prefill_message: "I am requesting a Triaxle Flatdeck."},
  {id: "rgn_doubledrop", hasDatasheet: true, name: "RGN / Double-drop", use:"Equipment specific loads, ideal for heavy equipment.", image: "doubledrop.png", weight: "62,000 lbs", prefill_id: 10, prefill_message: "I am requesting an RGN / Double-drop."},
  // {name: "Boosters", use:"Used to assemble custom load configurations.", image: "boosters.png", weight: ""},
  // {name: "Jeeps", use:"Used to assemble custom load configurations.", image: "jeeps.png", weight: ""},
  {id: "scissor-neck-winch", hasDatasheet: false, name: "Scissor-neck-winch", use:"For loading in remote areas, common in oilfield. Skid on, skid off. 10-foot wide deck.", image: "scissorneckwinch.png", weight: "54,000 - 75,000 lbs", prefill_id: 11, prefill_message: "I am requesting a Scissorneck Winch-truck."},
];


const services = [
  {name: "Oil and Gas", id: "oil_and_gas", shortDesc:"Action Express has decades of experience hauling for the Oil and Gas industry in Canada.", images: 3, aOrAn: 'an', prefill_id: 0, prefill_message: "I am interested in Oil and Gas freight services."},
  {name: "Mining", id: "mining", shortDesc:"Similar to Oil & Gas, Action Express also has decades of experience hauling for the Mining sector in Canada.", images: 2, aOrAn: 'a', prefill_id: 1, prefill_message: "I am interested in Mining freight services."},
  {name: "Logistics and Brokerage", id: "logistics_and_brokerage", shortDesc:"In addition to serving various sectors of industry, we've become proficient at Logistics and Brokerage and have grown to offer these services to our customers as well.", images: 3, aOrAn: 'a', prefill_id: 2, prefill_message: "I'm interested in Logistics and Brokerage."},
  {name: "Courier", id: "courier", shortDesc:"Action Express offers courier service within the province of Saskatchewan.", images: 3, aOrAn: 'a', prefill_id: 6, prefill_message: "I am requesting Courier services."},
  {name: "Winch and Skid", id: "winch_and_skid", shortDesc:"We have an extensive fleet of modern winches and skids to suit any need, large or small.", images: 2, aOrAn: 'a', prefill_id: 4, prefill_message: "I am requesting Winch and Skid freight service."},
  {name: "Less-than-Truckload", id: "less_than_truckload", shortDesc:"Action Express offers \"Less-than-truckload\", or \"LTL\" freight service.", images: 3, aOrAn: 'a', prefill_id: 5, prefill_message: "I'm interested in Less-than-Truckload freight service."},
  {name: "Hot Shot", id: "hot_shot", shortDesc:"Action Express also offers hot shot services, when a smaller, specific load needs to get somewhere fast.", images: 2, aOrAn: 'a', prefill_id: 3, prefill_message: "I am requesting Hot Shot service."},
  
];


var apiUrl = 'https://api.actionexpress.co';



const loadingPollingIntervalSecondsFraction = 4; // 1/10th


export {
  loadingPollingIntervalSecondsFraction,
  pages,
  fleet,
  apiUrl, 
  services
}